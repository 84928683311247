import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material'
import CampaignList from '../../components/CampaignList'
import { ChevronRight } from '@mui/icons-material'
import { CampaignForChurchMgmt } from '../../models/campaign'
import { grey } from '@mui/material/colors'
import { generateManageChurchCampaignsRoute } from '../../utils/routeGenerator'

interface CampaignsProps {
  id: string
  campaignList: CampaignForChurchMgmt[]
}

export default function ChurchCampaigns(props: CampaignsProps) {
  const { id, campaignList } = props

  return (
    <Stack
      display='flex'
      flexDirection='column'
      alignItems='center'
      border={1}
      borderColor={grey[400]}
      borderRadius={'16px'}
      padding={'16px'}
    >
      <Box
        id='recent-campaign-box'
        height={'480px'}
        width={'100%'}
        p='0'
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        alignItems={'flex-end'}
        position={'relative'}
        gap={'16px'}
      >
        <TableContainer>
          <Table>
            <TableBody>
              {campaignList.map((campaign) => (
                <CampaignList
                  key={'campaign-list-' + campaign.id}
                  id={campaign.id}
                  name={campaign.name}
                  startDate={campaign.startDate}
                  endDate={campaign.endDate}
                  currentDonationAmount={+campaign.budget.amount}
                  targetBudget={+campaign.budget.budget}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant='outlined'
          href={generateManageChurchCampaignsRoute(id)}
        >
          โครงการอื่น ๆ
          <ChevronRight />
        </Button>
      </Box>
    </Stack>
  )
}
