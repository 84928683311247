export default function formatDate(date: number | string) {
  return new Date(date ?? 0).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
}

export function formatCampaignFundingEndDate(endDate: number) {
  const remaining = Math.floor((endDate - Date.now()) / 1000 / 60 / 60 / 24)
  return remaining < 0
    ? `ปิดรับการบริจาคเมื่อ ${formatDate(endDate)}`
    : `${remaining} วัน จนถึง ${formatDate(endDate)}`
}
