import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Button,
  Divider,
  CardActions,
  Typography,
  Grid,
  Box,
  Modal,
  useMediaQuery,
} from '@mui/material'
import ProgressBar from './ProgressBar'
import { COLOR } from '../constants/color-constant'
import account from '../assets/icons/account.svg'
import fvg from '../assets/icons/fav.svg'

import { CampaignCard, CampaignCards } from '../models/campaign'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { generateViewCampaignRoute } from '../utils/routeGenerator'
import { formatCampaignFundingEndDate } from '../utils/formatDate'
import { useGet } from '../hooks/useFetch'
import AuthService from '../services/auth.service'
import { ROUTE } from '../constants/route-constant'
import BagService from '../services/BagService'
import { BsBagHeart } from 'react-icons/bs'

interface CampaignCardDetailProps {
  data: CampaignCard | null
  isLogin: boolean
}
function CampaignCardDetail(props: CampaignCardDetailProps) {
  const { data, isLogin } = props

  const campaignId = props.data?.campaignId
  const navigate = useNavigate()
  const [isInBag, setIsInBag] = useState<boolean>(data?.isInNamjaiBag || false)
  const [donated] = useGet<number>({
    url: `/campaigns/${campaignId}/donated-user-count`,
    autoFetch: true,
  })
  const isLargeScreen = useMediaQuery('(min-width:900px)')

  const [openModal, setOpenModal] = useState(false)

  if (!data) {
    return null
  }
  const handleAddToCart = () => {
    if (isLogin) {
      BagService.addToBag(data.campaignId, AuthService.getTokenUser() ?? '')
      setIsInBag(true)
    } else {
      navigate(ROUTE.LOGIN)
    }
  }

  const renderAddToCartButton = () => {
    if (isInBag) {
      return (
        <Link to={ROUTE.NAMJAI_BAG}>
          <BsBagHeart color={COLOR.SECOUNDARY_FIRST_1} size={35} />
        </Link>
      )
    } else {
      return (
        <Button
          onClick={handleAddToCart}
          sx={{
            height: '42px',
            animation: isInBag ? 'pop 1.5s ease-out' : 'none',
          }}
        >
          <img alt='add to bag' src={fvg} />
        </Button>
      )
    }
  }
  return (
    <Card sx={{ borderRadius: '20px' }}>
      <Button
        onClick={isLargeScreen ? () => setOpenModal(true) : undefined}
        href={
          isLargeScreen ? undefined : generateViewCampaignRoute(data.campaignId)
        }
        style={{
          all: 'initial',
          width: '100%',
          height: '100%',
          display: 'flex',
          cursor: 'pointer',
        }}
      >
        <CardMedia
          component='img'
          image={data.coverImageUrl}
          alt={`รูป campaign ${data.campaignName}`}
          sx={{
            objectFit: 'cover',
            width: '100%',
            height: isLargeScreen ? '250px' : undefined,
            minHeight: isLargeScreen ? undefined : '250px',
          }}
        />
      </Button>

      <Link to={generateViewCampaignRoute(data.campaignId)}>
        <CardActionArea>
          <CardContent>
            <Box sx={{ minHeight: '200px' }}>
              <Typography
                gutterBottom
                variant='h4'
                color={COLOR.PRIMARY_1}
                component='div'
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2, // Maximum number of lines
                  WebkitBoxOrient: 'vertical',
                  // maxHeight: '3.6em', // Adjust this to control the line height and ellipsis
                }}
              >
                {data.campaignName}
              </Typography>
              <Typography
                variant='body1'
                color='text.secondary'
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 4,
                }}
              >
                {data.description}
              </Typography>
            </Box>
            <Divider />
            <Typography variant='subtitle1' color='text' sx={{ mt: 1 }}>
              เป้าหมาย
            </Typography>
            <ProgressBar
              amount={data.currentDonationAmount}
              goal={data.targetBudget}
            ></ProgressBar>
            <Grid container direction='row' justifyContent='space-between'>
              <Typography variant='body1' color='text'>
                {data.fundingEndDate
                  ? formatCampaignFundingEndDate(data.fundingEndDate)
                  : ''}
              </Typography>
              <Grid display={'flex'}>
                <img alt='จำนวนผู้บริจาค' src={account}></img>
                <Typography variant='body1' color='text'>
                  {donated ?? '0'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Link>
      <CardActions
        sx={{
          m: '5px',
          justifyContent: 'space-around',
        }}
      >
        <Button
          style={{
            borderBlockWidth: '2px',
            borderRadius: '10px',
            borderColor: COLOR.PRIMARY_2,
            padding: '10px',
          }}
          fullWidth
          variant='outlined'
          href={generateViewCampaignRoute(data.campaignId) + '#JOIN'}
          sx={{ width: '80%', height: '42px' }}
        >
          <Typography variant='h5' color={COLOR.PRIMARY_2}>
            ร่วมมอบน้ำใจ
          </Typography>
        </Button>
        {renderAddToCartButton()}
      </CardActions>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            padding: '8px',
            borderRadius: '8px',
            boxShadow: 4,
            maxWidth: '60vw',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={data.coverImageUrl}
            alt={`ภาพหน้าปก ${data.campaignName}`}
            style={{
              maxWidth: '50vw',
              objectFit: 'contain',
            }}
          />
        </Box>
      </Modal>
    </Card>
  )
}

interface SectionCampaignCardProps {
  data: CampaignCards | null
}
export default function SectionCampaignCard(props: SectionCampaignCardProps) {
  const data = props.data
  const token = AuthService.getTokenUser()
  const [isLogin, setIsLogin] = useState(false)

  useEffect(() => {
    if (data && data.campaignCards && data.campaignCards.length > 0) {
      if (token) {
        setIsLogin(true)
      }
    }
  }, [data, token])

  function NoCampaignsFoundComponent() {
    return (
      <Box
        sx={{
          width: '100%',
          minHeight: '300px',
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant='h5' color={COLOR.PRIMARY_4}>
          ไม่พบ Campaign ที่ค้นหา
        </Typography>
      </Box>
    )
  }

  if (!data) {
    return null
  }

  if (data.campaignCards && data.campaignCards.length > 0) {
    return (
      <Box sx={{ width: '100%', minHeight: '300px', flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, md: 8, lg: 12 }}
        >
          {data.campaignCards.map((carddata, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <CampaignCardDetail data={carddata} isLogin={isLogin} />
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  }

  //   no campaigns found
  return <NoCampaignsFoundComponent />
}
