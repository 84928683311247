import { useState, useEffect, useRef, FormEvent } from 'react'
import { COLOR } from '../constants/color-constant'
import Slide from '../components/Slide'
import Navbar from '../components/Navbar/Navbar'
import {
  Box,
  Pagination,
  Typography,
  Stack,
  Container,
  TextField,
  InputAdornment,
  Divider,
  FormControl,
  MenuItem,
  Button,
} from '@mui/material'
import SectionCampaignCard from '../components/SectionCampaignCard'
import { StatSection, Compassion } from '../components/HomeComponents'
import { HomeSummary } from '../models/HomeStat'
import { CampaignCards } from '../models/campaign'
import AreaHolistic from '../components/AreaHolistic'
import Footer from '../components/Footer/Footer'
import SearchIcon from '@mui/icons-material/Search'
import Select from '@mui/material/Select'
import { useDebounce } from '../controller/useDebounce'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'
import { useGet, usePost } from '../hooks/useFetch'
import AuthService from '../services/auth.service'
import CardSkeleton from '../components/Skeletons/CardSkeleton'
function AllSearch() {
  const SearchComponentRef = useRef<HTMLDivElement | null>(null)
  const searchFieldRef = useRef<HTMLInputElement | null>(null)
  const theme = useTheme()

  const areaList = [
    'การพัฒนาเด็กแบบองค์รวม',
    'การศึกษา',
    'การปกป้องเด็ก',
    'สาธารณูปโภค',
    'เยาวชนผู้นำ',
    'สุขภาวะ',
  ]
  const areaLookupTable = [
    'ALL',
    'EDUCATION',
    'CHILD_SAFEGUARDING',
    'INFRASTRUCTURE',
    'YOUTH_LEADERSHIP',
    'WELL_BEING',
  ]
  const statusList = [
    'สถานะทั้งหมด',
    'กำลังระดมทุน',
    'ระดมทุนสำเร็จ',
    'ดำเนินการเสร็จสิ้น',
  ]
  const statusLookupTable = ['ALL STATUS', 'FUNDING', 'FUNDED', 'COMPLETED']
  interface CampaignParams {
    area?: string
    status?: string
    page: number
    campaignName?: string
  }

  const [areaSelec, setAreaSelec] = useState<number[]>([0])
  const [campaignKeyword, setCampaignKeyword] = useState('')
  const [statusSelec, setStatusSelec] = useState(0)
  const [page, setPage] = useState(1)
  const [params, setParams] = useState<CampaignParams>({
    page: 1,
  })

  const [homeSummary] = useGet<HomeSummary>({
    url: '/namjai/summary',
    autoFetch: true,
  })
  const [campaigns, homeCardLoading] = useGet<CampaignCards>({
    url: `/campaigns/card`,
    jwtToken: AuthService.getTokenUser() ?? '',
    autoFetch: true,
    queryParams: params,
  })

  const debouncedFetchCampaigns = useDebounce(fetchCampaigns, 500)
  const debouncedSetKeyword = useDebounce(
    (value) => setCampaignKeyword(value),
    300
  )

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (searchFieldRef.current) {
      searchFieldRef.current.blur()
    }

    event.preventDefault()
    if (SearchComponentRef.current) {
      SearchComponentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  useEffect(() => {
    setPage(1)
    debouncedFetchCampaigns()
  }, [areaSelec, statusSelec, campaignKeyword])

  const blinkAnimation = {
    animation: 'blink 1s infinite',
    '@keyframes blink': {
      '0%': {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      },
      '50%': {
        boxShadow: '0px 2px 4px rgba(0, 128, 0, 0.6)', // Green shadow
      },
      '100%': {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      },
    },
  }
  function fetchCampaigns() {
    const selectedAreas = areaSelec.map((index) => areaLookupTable[index])
    const areaParam = selectedAreas.includes('ALL')
      ? 'ALL'
      : selectedAreas.join(',')

    setParams({
      page: 1,
      ...(areaParam !== 'ALL' && { area: areaParam }),
      ...(statusSelec !== 0 && { status: statusLookupTable[statusSelec] }),
      ...(campaignKeyword && { campaignName: campaignKeyword }),
    })
  }
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value)
    setParams({ ...params, page: value })
    const element = document.getElementById('project-section')
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      })
    } else {
      //just for sure
      window.scrollTo(0, 500)
    }
  }
  const StatusSelect = () => {
    return (
      <FormControl
        sx={{
          minWidth: 150,
          '&:focus-within': {
            boxShadow: '0 0 3px 3px rgba(0, 0, 0, 0.1)',
          },
        }}
        variant='standard'
      >
        <Select
          disableUnderline
          variant='standard'
          value={statusSelec}
          onChange={(event) => setStatusSelec(Number(event.target.value))}
          autoWidth
          renderValue={(selected) => (
            <Typography variant='h5' color={COLOR.PRIMARY_2}>
              {statusList[selected]}
            </Typography>
          )}
        >
          <MenuItem value={0}>สถานะทั้งหมด</MenuItem>
          <MenuItem value={1}>กำลังระดมทุน</MenuItem>
          <MenuItem value={2}>ระดมทุนสำเร็จ</MenuItem>
          <MenuItem value={3}>ดำเนินการเสร็จสิ้น</MenuItem>
        </Select>
      </FormControl>
    )
  }
  return (
    <>
      <div className='flex min-h-screen flex-col'>
        <Navbar />
        <Box width={'100%'}>
          <Slide />
          <Container>
            <Box
              sx={{
                mt: 5,
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: {
                  xs: 'column',
                  lg: 'row',
                },
              }}
              component='form'
              noValidate
              onSubmit={handleSubmit}
            >
              <Box
                sx={{
                  width: {
                    xs: '100%',
                    lg: '85%',
                  },
                  border: 1,
                  borderRadius: '100px',
                  borderColor: COLOR.PRIMARY_2,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  px: 4,
                }}
              >
                <TextField
                  id='search'
                  inputRef={searchFieldRef}
                  type='search'
                  autoFocus
                  variant='standard'
                  onChange={(event) => {
                    debouncedSetKeyword(event.target.value)
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {!campaignKeyword && (
                            <>
                              <SearchIcon fontSize='large' />
                              <Typography
                                variant='h5'
                                color={COLOR.PRIMARY_2}
                                sx={{ marginLeft: '15px' }}
                              >
                                ค้นหา
                              </Typography>
                            </>
                          )}
                        </div>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                  sx={{
                    width: '100%',
                    height: '40px',
                    mt: '10px',
                  }}
                />
                {isLargeScreen && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      sx={{ mx: '10px' }}
                    />
                    <StatusSelect />
                  </Box>
                )}
              </Box>
              {isLargeScreen && (
                <Button
                  variant='outlined'
                  disabled={campaignKeyword === ''}
                  type='submit'
                  sx={{
                    color: COLOR.PRIMARY_2,
                    borderColor: COLOR.PRIMARY_2,
                    position: 'relative',
                    animation: campaignKeyword
                      ? blinkAnimation.animation
                      : 'none',
                    borderRadius: '100px',
                  }}
                >
                  <Typography variant='h6'>ค้นหา</Typography>
                </Button>
              )}
              {!isLargeScreen && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    mt: '20px',
                  }}
                >
                  <Box
                    sx={{
                      paddingLeft: '10px',
                      paddingRight: '4px',
                      border: 1,
                      borderRadius: '100px',
                      color: COLOR.PRIMARY_2,
                      borderColor: COLOR.PRIMARY_2,
                    }}
                  >
                    <StatusSelect />
                  </Box>

                  <Button
                    variant='outlined'
                    disabled={campaignKeyword === ''}
                    type='submit'
                    sx={{
                      // color: COLOR.PRIMARY_2,
                      // borderColor: COLOR.PRIMARY_2,
                      marginX: '10px',
                      // paddingRight: '4px',
                      borderRadius: '100px',
                      color: COLOR.PRIMARY_2,
                      borderColor: COLOR.PRIMARY_2,
                      position: 'relative',
                      animation: campaignKeyword
                        ? blinkAnimation.animation
                        : 'none',
                    }}
                  >
                    <Typography variant='h6'>ค้นหา</Typography>
                  </Button>
                </Box>
              )}
            </Box>
          </Container>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <AreaHolistic
              state={areaSelec}
              onAreaHolisticChange_hook={setAreaSelec}
              listsAreaName={areaList}
              multipleSelect={true}
            />
          </Box>
          <Container>
            <br />
            <Stack direction='row' justifyContent='center'>
              <Typography
                id='project-section'
                variant='h1'
                sx={{ userSelect: 'none' }}
                color={COLOR.PRIMARY_1}
              >
                โครงการของน้ำใจ
              </Typography>
            </Stack>
            <br />
            <Box ref={SearchComponentRef}>
              {homeCardLoading ? (
                <CardSkeleton cards={9} />
              ) : (
                <SectionCampaignCard data={campaigns} />
              )}
            </Box>

            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              marginY={'20px'}
            >
              <Pagination
                count={campaigns?.totalPages ?? 1}
                page={page}
                size='large'
                onChange={handleChangePage}
              />
            </Box>

            <br />
          </Container>
          <Box
            sx={{
              display: 'flex',
              backgroundColor: COLOR.BACKGROUD_MAIN_2,
              justifyContent: 'center',
              alignItems: 'center',
              py: '50px',
            }}
          >
            <StatSection data={homeSummary} />
          </Box>
          <Compassion />
        </Box>
      </div>
      <Footer />
    </>
  )
}

export default AllSearch
