import { Box, Card, Typography, Grid, Divider, Button } from '@mui/material'
import { FundingDetail } from '../../models/campaign'
import { COLOR } from '../../constants/color-constant'
import { useNavigate } from 'react-router-dom'
import ProgressBar from '../ProgressBar'
import { formatCampaignFundingEndDate } from '../../utils/formatDate'
import account from '../../assets/icons/account.svg'
import { ReactComponent as Facebook } from '../../assets/SocialIcons/Facebook.svg'
import ShareIcon from '@mui/icons-material/Share'
import BagService from '../../services/BagService'
import { ROUTE } from '../../constants/route-constant'
import AuthService from '../../services/auth.service'
import { BsBagHeart } from 'react-icons/bs'

export default function NowDonationCard(props: {
  campaignName: string
  campaignId: string | undefined
  isInBag: boolean
  fundingDetail: FundingDetail | null
  isAdminView: boolean
}) {
  const { campaignId, campaignName, isInBag, fundingDetail, isAdminView } =
    props
  const navigate = useNavigate()
  const FACEBOOK_URL = 'https://www.facebook.com/sharer/sharer.php?'
  const ICON_SIZE = 36

  const isFunded = fundingDetail?.fundingStatus === 'FUNDED'
  const borderColor = isFunded ? COLOR.GRAY_200 : COLOR.PRIMARY_1
  const textColor = isFunded ? COLOR.GRAY_200 : COLOR.SECOUNDARY_FIRST_1

  const handleAddToBag = () => {
    if (campaignId) {
      BagService.addToBag(campaignId, AuthService.getTokenUser() ?? '')
    }
    // use navigate it not need href for new tab ,
    // navigate to the bag page after add to bag success
    navigate(ROUTE.NAMJAI_BAG)
  }

  function handleShare() {
    const shareData = {
      title: `น้ำใจ แพลตฟอร์มระดมทุนเพื่อการพัฒนาเด็ก`,
      text: `ขอเชิญชวนให้ร่วมบริจาคกับโครงการ ${campaignName}`,
      url: window.location.href,
    }

    console.log(shareData)
    navigator.share(shareData)
  }

  return (
    <Card
      sx={{
        borderRadius: '20px',
        minHeight: '200px',
        p: '25px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
        boxShadow: 3,
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Typography variant='h6' color='text' sx={{ mt: 1 }}>
          ยอดบริจาคขณะนี้
        </Typography>
        <Typography variant='h2' color={COLOR.SECOUNDARY_FIRST_1}>
          {fundingDetail?.currentDonationAmount?.toLocaleString() ?? 0} บาท
        </Typography>
        <div
          className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
          style={{ backgroundColor: '#FFF2D0' }}
        >
          <Typography variant='h6' color={'#FF8600'}>
            {fundingDetail?.fundingStatus}
          </Typography>
        </div>

        <Typography variant='h6' color='text' sx={{ mt: 1 }}>
          เป้าหมาย
        </Typography>
        <ProgressBar
          amount={fundingDetail?.currentDonationAmount ?? 0}
          goal={fundingDetail?.targetBudget ?? 999999}
        ></ProgressBar>
        <Grid container direction='row' justifyContent='space-between'>
          <Typography variant='body2' color='text'>
            {fundingDetail?.endDate
              ? formatCampaignFundingEndDate(fundingDetail.endDate)
              : ''}
          </Typography>
          <Grid display={'flex'}>
            <img alt='account' src={account}></img>
            <Typography variant='body2' color='text'>
              {fundingDetail?.donatedUserCount ?? ' 9,999'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {!isAdminView && (
        <>
          <Button
            style={{
              borderBlockWidth: '2px',
              borderRadius: '10px',
              borderColor: borderColor,
            }}
            fullWidth
            variant='outlined'
            disabled={isFunded}
            onClick={() => {
              navigate({ hash: '#JOIN' })
            }}
            sx={{ width: '100%', height: '42px' }}
          >
            <Typography variant='h6' color={textColor}>
              {isFunded ? 'ปิดรับบริจาคแล้ว' : 'ร่วมบริจาค'}
            </Typography>
          </Button>
          {campaignId && isInBag ? (
            <Button
              style={{
                borderBlockWidth: '2px',
                borderRadius: '10px',
                borderColor: COLOR.PRIMARY_2,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
              }}
              fullWidth
              variant='outlined'
              href={ROUTE.NAMJAI_BAG}
            >
              <BsBagHeart color={COLOR.PRIMARY_2} size={25} />
              <Typography variant='h6' color={COLOR.PRIMARY_2}>
                ไปยัง กระเป๋าน้ำใจ
              </Typography>
            </Button>
          ) : (
            <Button
              style={{
                borderBlockWidth: '2px',
                borderRadius: '10px',
                borderColor: COLOR.PRIMARY_2,
              }}
              fullWidth
              variant='outlined'
              onClick={handleAddToBag}
              sx={{ width: '100%', height: '42px' }}
            >
              <Typography variant='h6' color={COLOR.PRIMARY_2}>
                เพิ่มลง กระเป๋าน้ำใจ
              </Typography>
            </Button>
          )}
        </>
      )}
      <Divider />
      <Typography variant='h5' color={COLOR.PRIMARY_1}>
        แชร์
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        }}
      >
        <a
          href={`${FACEBOOK_URL}${encodeURIComponent(window.location.href)}`}
          target='_blank'
          rel='noreferrer'
        >
          <Facebook
            color={COLOR.PRIMARY_1}
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        </a>
        <button onClick={handleShare}>
          <ShareIcon
            sx={{ color: COLOR.PRIMARY_1 }}
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        </button>
      </Box>
    </Card>
  )
}
