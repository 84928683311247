import { Suspense, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ROUTE } from '../constants/route-constant'
import { COLOR } from '../constants/color-constant'
import Slide from '../components/Slide'
import Navbar from '../components/Navbar/Navbar'
import { Box, Button, Typography, Stack, Container } from '@mui/material'
import SectionCampaignCard from '../components/SectionCampaignCard'
import {
  FundchildSection,
  StatSection,
  Compassion,
} from '../components/HomeComponents'
import { HomeSummary } from '../models/HomeStat'
import { CampaignCards } from '../models/campaign'
import AreaHolistic from '../components/AreaHolistic'
import Footer from '../components/Footer/Footer'
import { useGet, usePost } from '../hooks/useFetch'
import { LazyLoadedComponent } from '../components/Animations/LazyLoadedComponent'
import AuthService from '../services/auth.service'
import CardSkeleton from '../components/Skeletons/CardSkeleton'

function Home() {
  const navigate = useNavigate()

  const arealist = [
    'การพัฒนาเด็กแบบองค์รวม',
    'การศึกษา',
    'การปกป้องเด็ก',
    'สาธารณูปโภค',
    'เยาวชนผู้นำ',
    'สุขภาวะ',
  ]
  const areaLookupTable = [
    'ALL',
    'EDUCATION',
    'CHILD_SAFEGUARDING',
    'INFRASTRUCTURE',
    'YOUTH_LEADERSHIP',
    'WELL_BEING',
  ]
  const [areaSelec, setAreaSelec] = useState<number[]>([0])
  const [homeSummary] = useGet<HomeSummary>({
    url: '/namjai/summary',
    autoFetch: true,
  })

  const [homeCard, homeCardLoading] = useGet<CampaignCards>({
    url: '/campaigns/card',
    jwtToken: AuthService.getTokenUser() ?? '',
    autoFetch: true,
    queryParams: {
      ...(areaSelec[0] !== 0 && { area: areaLookupTable[areaSelec[0]] }),
    },
  })

  return (
    <>
      <div className='flex min-h-screen flex-col'>
        <Navbar />
        <Box width={'100%'}>
          <Slide />

          <FundchildSection data={homeSummary} />
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <AreaHolistic
              state={areaSelec}
              onAreaHolisticChange_hook={setAreaSelec}
              listsAreaName={arealist}
              multipleSelect={false}
            />
          </Box>
          <Container>
            <br />
            <Stack direction='row' justifyContent='center'>
              <Typography variant='h2' color={COLOR.PRIMARY_1}>
                โครงการที่กำลังระดมทุน
              </Typography>
            </Stack>
            <br />
            {homeCardLoading ? (
              <CardSkeleton cards={9} />
            ) : (
              <SectionCampaignCard data={homeCard} />
            )}

            <Stack direction='row' justifyContent='center'>
              <Button
                variant='outlined'
                fullWidth={false}
                onClick={() => navigate(ROUTE.ALL_CAMPAIGN)}
                sx={{ color: COLOR.PRIMARY_1, my: '20px' }}
              >
                <Typography variant='h5' color={COLOR.PRIMARY_1}>
                  ค้นหาโครงการต่าง ๆ
                </Typography>
              </Button>
            </Stack>
            <br />
          </Container>
          <Box
            sx={{
              display: 'flex',
              backgroundColor: COLOR.BACKGROUD_MAIN_2,
              justifyContent: 'center',
              alignItems: 'center',
              py: '50px',
            }}
          >
            <LazyLoadedComponent
              Component={<StatSection data={homeSummary} />}
            />
          </Box>
          <Compassion />
        </Box>
      </div>
      <Footer />
    </>
  )
}

export default Home
