import axios from 'axios'
import { toast } from 'react-toastify'
import createAPIErrorMessage from '../utils/createAPIErrorMessage'

const API_URL = process.env.REACT_APP_BASE_API
const API_KEY = process.env.REACT_APP_XKEY_API ?? ''

const getHeaders = (token: string) => ({
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json',
  'X-API-KEY': API_KEY,
})

const handleRequest = async (
  method: 'post' | 'delete',
  url: string,
  token: string
): Promise<void> => {
  try {
    const response = await axios({
      method,
      url: `${API_URL}${url}`,
      headers: getHeaders(token),
    })
  } catch (err: any) {
    const msg = createAPIErrorMessage(err)
    const statusCode = err.response?.status || 'Unknown'
    toast.error(`เกิดข้อผิดพลาด: ${msg} :ERROR(${statusCode})`)
  }
}

const BagService = {
  addToBag: (campaignId: string, token: string) =>
    handleRequest('post', `/namjai-bag/add/${campaignId}`, token),
}

export default BagService
