import { ADMIN_ROUTE } from '../constants/admin-route-constant'
import { ROUTE } from '../constants/route-constant'

export function generateChurchRoute(churchId: string): string {
  return generateRoute(ROUTE.CHURCH, { churchId })
}

export function generateManageChurchRoute(churchId: string): string {
  return generateRoute(ROUTE.MANAGE_CHURCH, { churchId })
}

export function generateManageChurchCampaignsRoute(churchId: string): string {
  return generateRoute(ROUTE.CHURCH_MANAGE_CAMPAIGN, { churchId })
}

export function generateEditChurchProposalRoute(churchId: string): string {
  return generateRoute(ROUTE.EDIT_CHURCH_PROPOSAL, { churchId })
}

export function generateViewCampaignRoute(campaignId: string): string {
  return generateRoute(ROUTE.VIEW_CAMPAIGN, { campaignId })
}

export function generateEditCampaignProposalRoute(campaignId: string): string {
  return generateRoute(ROUTE.EDIT_CAMPAIGN_PROPOSAL, { campaignId })
}

export function generateAdminChurchReceiptRoute(churchId: string): string {
  return generateRoute(ADMIN_ROUTE.CHURCH_RECEIPTS, { churchId })
}

export function generateChurchReceiptRoute(
  churchID: string,
  receiptID: string
): string {
  return generateRoute(ROUTE.CHURCH_RECEIPT, { churchID, receiptID })
}

export function generateCampaignProgressUpdateRoute(
  campaignId: string
): string {
  return generateRoute(ROUTE.CREATE_PROGRESS_UPDATE, { campaignId })
}

export function generateEditCampaignProgressUpdateRoute(
  requestId: string
): string {
  return generateRoute(ROUTE.EDIT_PROGRESS_UPDATE, { requestId })
}

export function generateViewCampaignUpdateRequestAdminRoute(
  requestId: string
): string {
  return generateRoute(ADMIN_ROUTE.CAMPAIGN_UPDATE_REQUEST_VIEW, { requestId })
}

export function generateViewChurchAdminRoute(churchId: string): string {
  return generateRoute(ADMIN_ROUTE.VIEW_CHURCH, { churchId })
}

export function generateViewCampaignAdminRoute(campaignId: string): string {
  return generateRoute(ADMIN_ROUTE.VIEW_CAMPAIGN, { campaignId })
}

export function generateEditCampaignAdminRoute(campaignId: string): string {
  return generateRoute(ADMIN_ROUTE.EDIT_CAMPAIGN, { campaignId })
}

export function generateEditCampaignProgressAdminRoute(
  campaignId: string,
  progressIndex: string
): string {
  return generateRoute(ADMIN_ROUTE.EDIT_CAMPAIGN_PROGRESS, {
    campaignId,
    progressIndex,
  })
}

export function generateViewCampaignRequestAdminRoute(
  requestId: string
): string {
  return generateRoute(ADMIN_ROUTE.CAMPAIGN_REQUEST_VIEW, { requestId })
}

export function generateViewChurchRequestAdminRoute(requestId: string): string {
  return generateRoute(ADMIN_ROUTE.CHURCH_REQUEST_VIEW, { requestId })
}

export function generateChurchCampaignProposalRoute(
  campaignId: string
): string {
  return generateRoute(ROUTE.EDIT_CAMPAIGN_PROPOSAL, { campaignId })
}
export function generateCreateCampaignProposalRoute(
  campaignRequestId: string
): string {
  return generateRoute(ROUTE.CREATE_CAMPAIGN_PROPOSAL, {
    campaignRequestId,
  })
}

export function generateWeeklyEarningAdminRoute(churchId: string): string {
  return generateRoute(ADMIN_ROUTE.WEEKLY_EARNING_LIST, {
    churchId,
  })
}

export function generateViewReceiptAdminRoute(
  churchId: string,
  confirmEarningId: string
): string {
  return generateRoute(ADMIN_ROUTE.ALL_RECEIPT_LIST, {
    churchId,
    confirmEarningId,
  })
}

function generateRoute(
  routePattern: string,
  params: Record<string, any> = {}
): string {
  let route = routePattern
  for (const param in params) {
    route = route.replace(`:${param}`, params[param])
  }
  return route
}
