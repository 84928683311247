import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import { useContext } from 'react'
import NAMJAIBAG from '../assets/images/namjaiBag.png'
import { Delete } from '@mui/icons-material'
import { COLOR } from '../constants/color-constant'
import axios from 'axios'
import AuthService from '../services/auth.service'
import { NamjaiListContext } from '../pages/NamjaiBag'
import { Link } from 'react-router-dom'
import { generateViewCampaignRoute } from '../utils/routeGenerator'

interface NamjaiBagListProps {
  namjaiBagId: string
  campaignId: string
  campaignName: string
  coverImageUrl?: string
  campaignAmount: number
  onDelete: () => void
}

export default function NamjaiBagList(props: NamjaiBagListProps) {
  const API_URL = process.env.REACT_APP_BASE_API
  const API_KEY = process.env.REACT_APP_XKEY_API ?? ''

  const token = AuthService.getTokenUser()

  const {
    namjaiBagId,
    campaignId,
    campaignName,
    coverImageUrl,
    campaignAmount,
    onDelete,
  } = props

  const [namjaiBagListStatus, setNamjaiBagListStatus] =
    useContext(NamjaiListContext)

  const handleDelete = async (namjaiBagId: string) => {
    setNamjaiBagListStatus('loading')
    try {
      await axios.delete(`${API_URL}/namjai-bag/${namjaiBagId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-API-KEY': `${API_KEY}`,
        },
      })

      setNamjaiBagListStatus('ready')
      onDelete()
    } catch (error) {
      // Handle errors (e.g., display an error message)
      console.error('Error deleting item:', error)
    }
  }

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      width={'100%'}
      flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
      gap={'12px'}
    >
      <Box
        id='namjai-bag-list-pic'
        component={'img'}
        height={{ xs: '100%', sm: '112px' }}
        width={{ xs: '100%', sm: '196px' }}
        minHeight={{ xs: '100%', sm: '112px' }}
        minWidth={{ xs: '100%', sm: '196px' }}
        sx={{ objectFit: 'cover' }}
        src={coverImageUrl ?? NAMJAIBAG}
        alt={`รูปภาพปก ${campaignName}`}
      />
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={'8px'}
        flexGrow={1}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
      >
        <Box
          id='namjai-bag-list-name'
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          minWidth={'250px'}
          width={'100%'}
          maxWidth={'600px'}
        >
          <Button
            variant='text'
            disabled={namjaiBagListStatus === 'loading'}
            sx={{ padding: 0 }}
            component={Link}
            to={generateViewCampaignRoute(campaignId)}
          >
            <Typography variant='h4' color={COLOR.PRIMARY_1} align='left'>
              {campaignName}
            </Typography>
          </Button>

          <Typography variant='h6'>
            ต้องการ {campaignAmount?.toLocaleString('en-US')} บาท
            เพื่อถึงเป้าหมาย
          </Typography>
        </Box>
        <Box
          id='namjai-bag-list-edit-area'
          display={'flex'}
          alignItems={'center'}
          gap={'8px'}
        >
          <Button
            color='primary'
            variant='outlined'
            disabled={namjaiBagListStatus === 'loading'}
            component={Link}
            to={generateViewCampaignRoute(campaignId)}
            sx={{ width: '100px' }}
          >
            <Typography variant='h6'>บริจาค</Typography>
          </Button>
          <IconButton
            aria-label='นำออกจากกระเป๋า'
            color='error'
            disabled={namjaiBagListStatus === 'loading'}
            onClick={() => handleDelete(namjaiBagId)}
          >
            <Delete
              color={namjaiBagListStatus === 'loading' ? 'disabled' : 'error'}
            />
          </IconButton>
        </Box>
      </Stack>
    </Stack>
  )
}
