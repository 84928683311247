import { Box, Button, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { ReactComponent as Nodata } from '../../assets/icons/nodata.svg'
import { generateManageChurchCampaignsRoute } from '../../utils/routeGenerator'
import { ChevronRight } from '@mui/icons-material'
import { UserData } from '../../models/UserTypes'
import { useUser } from '../../services/UserContext'

function NoRecentCampaigns({ churchId }: { churchId: string }) {
  const userData: UserData | null = useUser().userData

  return (
    <Box
      id='no-recent-campaign-box'
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={'8px'}
      textAlign={'center'}
    >
      <Nodata width={'100px'} />
      <Typography variant='subtitle1' color={grey[500]}>
        คริสตจักรยังไม่มีโครงการที่ดำเนินการอยู่
      </Typography>
      {userData?.userrole.churchId === churchId && (
        <Button
          variant='outlined'
          href={generateManageChurchCampaignsRoute(churchId)}
        >
          ดูโครงการที่กำลังเสนอ
          <ChevronRight />
        </Button>
      )}
    </Box>
  )
}

export default NoRecentCampaigns
